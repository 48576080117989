// app.component.ts
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from './pages/admin/commons/services/util.service';
import { ThemeService } from './pages/admin/commons/services/theme.service';

@Component({
  standalone: true,
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  imports: [RouterOutlet],
})
export class AppComponent {
  private _translate = inject(TranslateService);
  private _utilService = inject(UtilService);
  private _themeService = inject(ThemeService); // Inyectar el servicio de tema

  constructor() {
    // Configuración de idiomas
    this._translate.addLangs(['en', 'es', 'pt']);
    const ln = this._utilService.navigatorLanguage;
    this._translate.setDefaultLang(ln ? ln : 'en');

    // Sincronizar el tema desde el servicio
    this._themeService.syncTheme();
  }
}
