// theme.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private darkModeSubject: BehaviorSubject<boolean>;
  darkMode$;

  constructor() {
    // Leer el tema guardado en localStorage al inicializar
    const savedTheme = localStorage.getItem('isDarkMode');
    const isDarkMode = savedTheme ? JSON.parse(savedTheme) : false;

    // Inicializar darkModeSubject y asignar el observable
    this.darkModeSubject = new BehaviorSubject<boolean>(isDarkMode);
    this.darkMode$ = this.darkModeSubject.asObservable();

    // Aplicar el tema al cargar
    this.applyTheme(isDarkMode);
  }

  toggleDarkMode(): void {
    // Alternar el modo oscuro
    const newMode = !this.darkModeSubject.value;
    this.darkModeSubject.next(newMode);

    // Guardar en localStorage
    localStorage.setItem('isDarkMode', JSON.stringify(newMode));

    // Aplicar el tema
    this.applyTheme(newMode);
  }

  private applyTheme(isDarkMode: boolean): void {
    // Añadir o eliminar la clase dark-mode al <body>
    document.body.classList.toggle('dark-mode', isDarkMode);
  }

  // Método público para sincronizar el tema sin alternar
  syncTheme(): void {
    const isDarkMode = this.darkModeSubject.value;
    this.applyTheme(isDarkMode);
  }
}
